<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @eventUpdateOk="getTabelData" />
        <FilterBlock @search="search" />
        <UploadData
            uploadUrl="/web/bas-work-step/import-data"
            downloadUrl="/download-work-step-import-template"
            tips="导入工序数据,如果数据已存在则进行更新"
            v-model:visible="showUploadDataModal"
            @uploadSuccess="getTabelData"
        />
        <div class="table-content">
            <div class="tree-list">
                <a-input-search
                    v-model:value="keyWords"
                    placeholder="请输入关键词以检索"
                    enter-button
                    @search="handleSearchTree"
                />
                <a-tree
                    :tree-data="treeData"
                    :fieldNames="{
                        title: 'name',
                    }"
                    @select="handleSelectTree"
                >
                    <template #title="{ name, key, code }">
                        <a-tooltip>
                            <template #title>【{{ code }}】{{ name }}</template>
                            【{{ code }}】{{ name }}
                        </a-tooltip>
                    </template>
                </a-tree>
            </div>
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ record.index + 1 }}
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a
                                v-perm="'btn:sys-dictionary-edit'"
                                @click="() => openUpDateModal(record.id)"
                            >
                                编辑
                            </a>
                            <a v-perm="'btn:sys-dictionary-delete'" @click="deleteRow(record.id)">
                                删除
                            </a>
                            <a @click="handleMoveUp(record.id, record.index)">上移</a>
                            <a @click="handleMoveDown(record.id, record.index)">下移</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-space direction="horizontal" :size="8">
                            <a-button
                                type="primary"
                                @click="() => openUpDateModal('')"
                                v-perm="'btn:sys-dictionary-add'"
                            >
                                新增
                            </a-button>
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import UploadData from "@/components/UploadData";
import { confirmModal, showMessage } from "@/utils/common";
import {
    apiDataDictionaryList,
    apiDataDictionaryEnable,
    apiDataDictionaryDelete,
    apiDataDictionaryMoveUp,
    apiDataDictionaryMoveDown,
    apiDataDictionaryTypes,
} from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        UploadData,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "字典项编码",
                dataIndex: "code",
            },
            {
                title: "字典项名称",
                dataIndex: "name",
            },
            {
                title: "字典项说明",
                dataIndex: "description",
            },
            {
                title: "启用状态",
                dataIndex: "enabled",
                key: "enabled",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 200,
            },
        ];

        const state = reactive({
            showUploadDataModal: false,
            upDateId: null,
            params: null,
            data: [],
            treeData: [],
            activeKey: "1",
            loading: false,
            keyWords: "",
            pCode: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const getTreeData = async () => {
            let res = await apiDataDictionaryTypes();
            if (res.status === "SUCCESS") {
                state.treeData = res?.data;
            }
        };
        getTreeData();

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            let res = await apiDataDictionaryList({
                pCode: state.pCode,
                ...params,
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data?.length;
                state.data = res.data.map((item, index) => ({
                    index,
                    ...item,
                }));
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const openUpDateModal = id => {
            state.upDateId = id;
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "字典禁用后，之后将无法在业务流程中使用该字典信息，是否确认禁用？"
                : "字典启用后，该字典可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiDataDictionaryEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "字典项删除后将无法恢复，是否确认删除该字典项？",
                onOk: async () => {
                    let res = await apiDataDictionaryDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const handleMoveUp = async (id, index) => {
            if (index == 0) return showMessage("warning", "已是第一条");
            let res = await apiDataDictionaryMoveUp(id);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                getTabelData();
            }
        };

        const handleMoveDown = async (id, index) => {
            if (index + 1 == state.pagination.total) return showMessage("warning", "已是最后一条");
            let res = await apiDataDictionaryMoveDown(id);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                getTabelData();
            }
        };

        const handleSearchTree = value => {
            if (!value) return getTreeData();
            let res = [];
            const cycleSearch = treeData => {
                for (let item of treeData) {
                    if (item.name == value) {
                        res = [item];
                        break;
                    } else if (item?.children && item?.children?.length) {
                        return cycleSearch(item?.children);
                    }
                }
            };
            cycleSearch(state.treeData);
            state.treeData = res;
        };

        const handleSelectTree = (key, e) => {
            state.pagination.current = 1;
            state.pCode = e.selected ? e.node.code : "";
            getTabelData();
        };

        return {
            columns,
            ...toRefs(state),
            openUpDateModal,
            changeSwitch,
            deleteRow,
            search,
            paginationChange,
            getTabelData,
            handleMoveUp,
            handleMoveDown,
            handleSearchTree,
            handleSelectTree,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-collapse-header) {
    padding: 20px 0 0 !important;
}
:deep(.ant-collapse-content-box) {
    padding: 0;
    padding-top: 0 !important;
}
.ant-input-group-wrapper {
    margin-bottom: 10px;
}
.container {
    .type {
        margin: 10px 0;
        cursor: pointer;
        padding-left: 40px;
    }
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        .tree-list {
            width: 200px;
            margin-right: 10px;
        }
        .ant-table-wrapper {
            flex: 1;
            width: 0;
        }
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
