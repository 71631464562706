<template>
    <DictionaryCategory
        :data="dictionaryCategoryOptions"
        v-model:categoryVisible="dictionaryCategoryVisible"
        typeName="字典"
        @onSelect="onSelectDictionaryCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}字典`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="600px"
    >
        <a-form
            :model="formData"
            ref="formRef"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="24">
                    <a-form-item
                        label="字典项编码"
                        name="code"
                        :rules="[
                            { required: true, message: '请输入字典项编码' },
                            { validator: checkAllSpace },
                        ]"
                        style="margin-bottom: 20px"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 73%; margin-right: 20px"
                                    :disabled="sysCreate || Boolean(upDateId)"
                                    v-model:value="formData.code"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="sysCreate"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="字典项名称"
                name="name"
                :rules="[
                    { validator: checkAllSpace },
                    { required: true, message: '请输入字典项名称' },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="字典类别"
                name="pName"
                v-if="!upDateId"
                :rules="[{ required: true, message: '请选择字典类别' }]"
            >
                <a-form-item>
                    <SelectInput
                        :title="formData.pName"
                        :onClick="() => (dictionaryCategoryVisible = true)"
                        :showDeleteIcon="false"
                    ></SelectInput>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="字典项说明"
                name="description"
                :rules="[{ validator: checkAllSpace }]"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import {
    apiProcessUpdate,
    apiDataDictionaryDetail,
    apiDataDictionaryTypes,
    apiDataDictionaryUpdate,
    apiDataDictionarySave,
} from "@/api";
import Select from "@/components/Select";
import DictionaryCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import SelectInput from "@/components/SelectInput";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk"],
    components: {
        Select,
        DictionaryCategory,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            sysCreate: false,
            dictionaryCategoryVisible: false,
            dictionaryCategoryOptions: [],
            formRef: null,
            formData: {
                id: "",
                name: "",
                code: "",
                pCode: "",
                pName: "",
                description: "",
            },
        });

        const getTypes = async () => {
            let res = await apiDataDictionaryTypes();
            if (res.status === "SUCCESS") {
                state.dictionaryCategoryOptions = res?.data;
            }
        };

        const handleCancel = () => {
            state.formRef.resetFields();
            state.formData.id = "";
            state.visible = false;
            state.sysCreate = false;
            state.autoCreateCode = "";
            emit("update:upDateId", null);
        };

        const onFinish = async () => {
            let fn = props.upDateId ? apiDataDictionaryUpdate : apiDataDictionarySave;
            let res = await fn(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiDataDictionaryDetail(id);
            if (res.status === "SUCCESS") {
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = res.data ? res.data[key] : "";
                });
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectDictionaryCategory = ([value]) => {
            state.formData.pName = value?.name;
            state.formData.pCode = value?.code;
        };

        const getOrder = async () => {
            let code = await getAutoCreateCode("BAS_DICTIONARY");
            state.autoCreateCode = code;
            state.formData.code = code;
        };

        watch(
            () => state.sysCreate,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.code = state.autoCreateCode;
                }
                if (!newVal) state.formData.code = "";
            }
        );

        watch(
            () => props.upDateId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getTypes();
                    newVal && getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
            onSelectDictionaryCategory,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
</style>
